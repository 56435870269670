html, body {
    min-height: 100%;
}

img.iconfirst {
	width: 154px;
	margin-top: -8px;
    margin-right: -2px;
}
.firsttitle {
	color: #c9f3c6;
	font-size: 28px;
	margin-top: -30px;
	text-transform: uppercase;
}
p.baseline {
font-family: lobster;
	color: #84e47b;
	font-size: 6em;
}

.top-bar.top-bar-scrolled {
	background-color: #84e47b;
	.menu a {
		color: $primary-color;
	}
	
}
#main-content-section {
	margin-top: 60px
}

h2 {
	padding: 2em 0 1em 0;
	color: $primary-color;
}

p.extraaandacht {
	padding: 1em 0;
	margin: 0 auto;
	font-size: larger;
	font-weight: bold;
}

a.ahome {
	font-family: 'Libre Franklin', sans-serif;
	font-size: larger;
	margin: 2em 0 3em 0;
	
    text-decoration: none;
    cursor: pointer;
    svg {
    	fill: $primary-color;
    }
    .verwijs {
    	font-size: 40px;
    }
}
a {
	.verwijs2 {
		font-size: 20px;
	}
	.verwijs3 {
		font-size: 20px;
		margin-top: 5px;
		margin-bottom: 25px;
	}
}
.boeksectie {
	margin-top: 2em;
	img.cover {
		width: 200px;
	}

	.boekfirst {
		background-color: #dcf1bd;
		margin: 0;

		a.buy {

			i.fas.mycart {
			  display: inline-block;
			  border-radius: 60px;
			  box-shadow: 0px 0px 2px $primary-color;
			  padding: 0.5em 0.6em;
			  font-size: 2em;
			  line-height: 1.3em;
			  background-color: $primary-color;
			  color: white;
			  

			  &:hover {
			  	color: $primary-color;
			  	background-color: #54da48;
			  	cursor: copy;
			  }

			  &.soldout {
			  	color: $primary-color;
			  	background-color: white;
			  	cursor: default; 
			  }


			}
		}
		button.buy {

			i.fas.mycart {
			  display: inline-block;
			  border-radius: 60px;
			  box-shadow: 0px 0px 2px $primary-color;
			  padding: 0.5em 0.6em;
			  font-size: 1.4em;
			  line-height: 1.3em;
			  background-color: $primary-color;
			  color: white;
			  

			  &:hover {
			  	color: $primary-color;
			  	background-color: #54da48;
			  	cursor: copy;
			  }

			  &.soldout {
			  	color: $primary-color;
			  	background-color: white;
			  	cursor: default; 
			  }

			}

		}
		input.addtocart.button {
			display: inline-block;
			border-radius: 60px;
			box-shadow: 0px 0px 2px $primary-color;
			padding: 0.5em 0.6em;
			font-family: 'Font Awesome\ 5 Free';
			font-weight: 900;
			font-size: 1.4em;
			line-height: 1.3em;
			background-color: $primary-color;
			color: white;


			&:hover {
				color: $primary-color;
				background-color: #54da48;
				cursor: copy;
			}

			&.soldout {
				color: $primary-color;
				background-color: white;
				cursor: default; 
			}
		}
		h3 {
			line-height: 1.1;
		}

		p.auteursnaam {
			margin: 1rem 0 0 0;
			font-weight: bold;
		}
		p.ondertitel {
			margin-top: -7px;
			color: #808080;
		}

	}
	.tabs {
		border: 0;
		li.tabs-title  {
			a {
				font-size: 1rem;
				font-variant: small-caps;
				padding: 1rem 1rem;
			}
			a[aria-selected='true'] {
		    	background-color: #dcf1bd;
			}
			a:focus {
				background-color: #dcf1bd;
			}
		} 	
	}
	.tabs-content{
		border: 0;
		.tabs-panel {
			p.trick {
				color: white;
			}
			
		}
	}
}

.charlotte, .contact {
	padding: 0px 25px;
}

.inspringenverder {
	padding-left: 13px;
}
.inspringen {
	padding-left: 10px;
}
.adresicoontje {
	color: $primary-color;
}
.emaillink {
	color: black;
	text-decoration: underline;
}

#contactformulier {
	label,fieldset,p {
		margin-left: 15px;
	}
	input.testhq {
    	width: 100px;
	}
	input:focus::-webkit-input-placeholder { color:transparent; }
	input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
	input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
	input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

	&.ingevuld {
		display: none;
	}
}

.reveal {
	border: 4px solid #84e47b;
}
.welkom {
	margin: 2em 0 1em 0;
	font-family: lobster;
	color: #84e47b;
	font-size: 35px;
}
.fototuin {
	margin-bottom: 100px;
}
		
@media only screen and (max-width: 640px) {
    /* For mobile phones: */
	img.iconfirst {
	    width: 80px;
	    margin-top: -4px;
	}
	.firsttitle {
	    font-size: 17px;
	    margin-top: -94px;
	}
	p.baseline {
	    font-size: 3.7em;
	    line-height: 1;
	    padding: 0 .5em;
	}
	.hero-full-screen .bottom-content-section {
    	bottom: 6em;
	}
	.atthebottom {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #84e47b;
		font-size: 34px;
		color: #50544f;
	}
	p.sub {
		font-size: 14px;
	    display: block;
	    font-variant: small-caps;
	    margin-bottom: .2rem;
	    margin-top: -0.7rem;
	}
	img.logoatbottom {
	    width: 63px;
	    margin-top: 5px;
	}

	.boeksectie {
		img.cover {
		    width: 250px;
		    padding: 25px 0px;
		}
		.tabs li.tabs-title a {
    		font-size: .85rem;
    		padding: .7rem .4rem;
    	}
    	.boekfirst {
			button.buy {

				i.fas.mycart {
				  font-size: 1.2em;
				}

			}
			h3 {
				line-height: 1.2;
				margin-top: 5px;
			}
			p.auteursnaam {
				font-size: .83rem;
			}
			
			p.ondertitel {
			    margin-top: -5px;
			    color: #808080;
			    font-size: smaller;
			    line-height: 1.1rem;
			    margin-bottom: 4px;
			}

    	}
	}
	.adresbord {
		margin-bottom: 30px;
		img.adresbordje {
			width: 290px;;
		}
	}
}
