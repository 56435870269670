/*$hero-full-screen-bg: url('https://images.pexels.com/photos/378273/pexels-photo-378273.jpeg?w=1260&h=750&auto=compress&cs=tinysrgb') center center no-repeat; */
$hero-full-screen-bg: url('../img/logouitgeverij.jpg?w=1260&h=750&auto=compress&cs=tinysrgb') center center no-repeat;
$hero-full-screen-bg-size: cover;
//$hero-full-screen-text-color: $white;
$hero-full-screen-text-color: #84e47b;


.hero-full-screen {
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background: $hero-full-screen-bg;
  background-size: $hero-full-screen-bg-size;

  .middle-content-section {
    text-align: center;
    color: $hero-full-screen-text-color;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }


  .bottom-content-section {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    svg {
      height: rem-calc(60);
      width: rem-calc(60);
      fill: $hero-full-screen-text-color;
      //fill: $primary-color;
    }
  }

  // optional demo styles
  .top-bar {
    background: transparent;

    .menu {
      background: transparent;

      a.topicon {
        padding: 0;
        img.icontop {
          width: 100%;
        }
      }
    }

    .menu-text {
      color: $hero-full-screen-text-color;
      padding: 0;
      margin: 0 15px;
      width: 50px;
    }

    .menu li {
      display: flex;
      align-items: center;
    }

    .menu a {
      color: $hero-full-screen-text-color;
      font-weight: bold;
    }
  }
}
